@font-face {
    font-family: "Gilroy";
    src: url("../fonts/Gilroy-Regular.eot");
    src: local("Gilroy Regular"), local("Gilroy-Regular"), url("../fonts/Gilroy-Regular.woff") format("woff"), url("../fonts/Gilroy-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("../fonts/Gilroy-Medium.eot");
    src: local("Gilroy Medium"), local("Gilroy-Medium"), url("../fonts/Gilroy-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-Medium.woff2") format("woff2"), url("../fonts/Gilroy-Medium.woff") format("woff"), url("../fonts/Gilroy-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("../fonts/Gilroy-Light.eot");
    src: local("Gilroy Light"), local("Gilroy-Light"), url("../fonts/Gilroy-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-Light.woff2") format("woff2"), url("../fonts/Gilroy-Light.woff") format("woff"), url("../fonts/Gilroy-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy";
    src: url("../fonts/Gilroy-Semibold.eot");
    src: local("Gilroy Semibold"), local("Gilroy-Semibold"), url("../fonts/Gilroy-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-Semibold.woff2") format("woff2"), url("../fonts/Gilroy-Semibold.woff") format("woff"), url("../fonts/Gilroy-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gilroy-Bold";
    src: url("../fonts/Gilroy-Bold.eot");
    src: local("Gilroy Bold"), local("Gilroy-Bold"), url("../fonts/Gilroy-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Gilroy-Bold.woff2") format("woff2"), url("../fonts/Gilroy-Bold.woff") format("woff"), url("../fonts/Gilroy-Bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Deluce";
    src: url("../fonts/Deluce.eot");
    src: local("Deluce"), local("Deluce"), url("../fonts/Deluce.eot?#iefix") format("embedded-opentype"), url("../fonts/Deluce.woff2") format("woff2"), url("../fonts/Deluce.woff") format("woff"), url("../fonts/Deluce.ttf") format("truetype");
    font-display: swap;
}

body {
    font-family: Gilroy !important;
    overflow-x: hidden;
}

a {
    text-decoration: none !important;
}

p {
    font-size: 14px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.heading {
    font-size: 70px;
    color: #000000;
    margin: 0px;
    font-family: "Deluce";
    line-height: 1;
}

.header-gap {
    margin-top: 67px;
}

.center-heading {
    text-align: center;
}

ul {
    margin: 0px !important;
    padding: 0 !important;
    list-style: none;
}

.default-btn {
    padding: 10px 20px;
    text-align: center;
    display: inline-block;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: 0.4s ease;
    color: #0E0100;
    font-family: Gilroy;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 600;
    border-radius: 2px;
    box-shadow: none;
    border: 0px;
    background: #B7A9A5;
}

.default-btn:before,
.default-btn:after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background-color: rgb(0 0 0 / 50%);
    transition: all 0.7s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.default-btn:before {
    right: 0;
    top: 0;
}

.default-btn:after {
    left: 0;
    bottom: 0;
}

.default-btn:hover {
    color: #0E0100;
}

.default-btn span {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    z-index: 1;
}

.default-btn:hover:before,
.default-btn:hover:after {
    width: 100%;
}

.default-btn span:before,
.default-btn span:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 0;
    background-color: rgb(0 0 0 / 50%);
    transition: all 0.3s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.default-btn span:before {
    right: 0;
    top: 0;
    transition: all 0.5s cubic-bezier(0.35, 0.1, 0.25, 1);
}

.default-btn span:after {
    left: 0;
    bottom: 0;
}

.default-btn:hover span:before,
.default-btn:hover span:after {
    height: 100%;
}

.black-btn {
    background: #000000;
    color: #FFFFFF;
}

.black-btn:hover {
    color: #fff;
}

.black-btn.default-btn:before,
.black-btn.default-btn:after {
    background-color: #B7A9A5;
}

.black-btn.default-btn span:before,
.black-btn.default-btn span:after {
    background-color: #B7A9A5;
}

.slick-dots {
    text-align: center;
    bottom: 0px;
    margin: 0;
    left: 0;
    right: 0;
}

.slick-dots li {
    width: 10px !important;
    height: 10px !important;
    margin: 0;
    margin-right: 10px;
}

.slick-dots li:last-child {
    margin-right: 0px;
}

.slick-dots li button {
    display: block;
    width: 10px !important;
    height: 10px !important;
    padding: 0 !important;
    border: 0px !important;
    border-radius: 0px;
    background: rgb(217 217 217 / 50%);
    margin: 0px !important;
    transform: rotate(45deg);
}

.slick-dots li.slick-active button {
    background: #B7AAA5;
}

.slick-dots li button::before {
    display: none;
}

.default-arrowleft,
.default-arrowright {
    align-items: center;
    border-radius: 50%;
    border: 1px solid #B3A693;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: all .2s ease;
    width: 45px;
    height: 45px;
    color: #B3A693;
    font-size: 13px;
    margin: 0px 4px;
}

.default-arrowleft:hover,
.default-arrowright:hover {
    background: #B3A693;
    color: #0E0100;
}

.header-area {
    padding: 18px 0px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    border-bottom: 1px solid #fff;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}

.logo {
    text-align: center;
}

.logo a {
    display: inline-block;
}

.logo img {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    width: 140px;
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(invert=1)";
}

.stick {
    padding: 15px 0px;
    background: #fff;
    border-color: #000;
    -webkit-animation: slideDownBar 0.7s ease-out;
    animation: slideDownBar 0.7s ease-out;
}

@keyframes slideDownBar {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}

.stick .logo img {
    width: 130px;
    filter: none;
}

.header-menubar {
    display: flex;
    align-items: center;
    justify-content: end;
}

.header-menulink li {
    margin-right: 35px;
    display: inline-block;
}

.header-menulink li:last-child {
    margin-right: 0px;
}

.header-menulink li a {
    font-size: 13px;
    position: relative;
    text-transform: uppercase;
    transition: all 0.2s ease-In;
    font-weight: 600;
    letter-spacing: 1px;
    color: #ffffff;
    outline: none;
}

.header-menulink li a:hover,
.header-menulink li a:focus {
    color: #ffffff;
}

.header-menulink li a:before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    bottom: -3px;
    transition: 0.5s transform ease;
    transform: scale3d(0, 1, 1);
    transform-origin: 50% 50%;
    background: #fff;
}

.stick .header-menulink li a {
    color: #000;
}

.stick .header-menulink li a:before {
    background: #000000;
}

.header-menulink li a:hover::before {
    transform: scale3d(1, 1, 1);
}

.rightside-menu {
    text-align: right;
}

.header-menulink .dropdown-menu {
    padding: 20px;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.07);
    top: 30px !important;
    transform: translateY(30px) !important;
    transition: all .4s ease-out;
    opacity: 0;
    visibility: hidden;
    display: block;
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto;
    width: max-content;
    border-color: #B8ABA5;
}

.header-menulink .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px) !important;
}

.drop-menulinks a {
    color: #000000 !important;
    display: table;
    margin-bottom: 15px;
}

.drop-menulinks a:last-child {
    margin-bottom: 0px;
}

.drop-menulinks a:before {
    background: #000 !important;
}

.mobile-menu {
    background: #000000;
    padding: 8px 0px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 8;
    display: none;
    align-items: center;
    justify-content: space-between;
}

.mobile-menu a {
    width: 17%;
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-menu a img {
    transition: all 0.2s ease-In;
    filter: brightness(0) invert(1);
}

.mobile-menu a:hover img {
    filter: none;
}

.mobile-menu a:nth-child(3) {
    background: #B7A9A5;
}

.mobile-menu a:nth-child(3) img {
    filter: none;
}

.white-header {
    border-color: #000;
    background: #fff;
}

.white-header .logo img {
    filter: none;
}

.white-header .header-menulink li a {
    color: #000;
}

.white-header .header-menulink li a:before {
    background: #000000;
}

.banner-section {
    position: relative;
}

.banner-section::before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 40%);
    z-index: 1;
    width: 100%;
    height: 100%;
}

.homebanner-detail div {
    left: 50%;
    position: absolute;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    z-index: 1;
    height: 100%;
}

.homebanner-detail div .heading {
    color: #ffffff;
    text-transform: lowercase;
}

.homebanner-detail{
    width: 100%;
    height: 100vh;
    background: linear-gradient(to right, #000, rgb(0, 0, 0, 0.5));
}

.homebanner-detail .img-responsive,
.homebanner-detail video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.play-butn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid #fff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    bottom: 50px;
}

.play-butn:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 50px;
    height: 50px;
    background: rgb(255 255 255 / 60%);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.8);
        opacity: 0;
    }
}

.home-build {
    margin: 60px 0px;
}

.homebuild-image {
    position: relative;
}

.homebuild-shape,
.homebuild-shape-2 {
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    margin: 0 auto;
    z-index: 1;
    width: 110px;
}

.homebuild-shape-2 {
    top: auto;
    bottom: -1px;
    transform: rotate(180deg);
}

.homebuild-image .img-responsive {
    border-radius: 10px;
}

.build-slider {
    margin-left: 70px;
}

.build-slider .slick-slide {
    padding: 0px 5px;
}

.buildslide-box .heading {
    text-transform: lowercase;
}

.buildslide-box div {
    margin: 40px 0px 0px 0px;
}

.buildslide-box p {
    color: rgb(0 0 0 / 60%);
    margin-bottom: 20px;
    text-align: justify;
}

.buildslide-box p:last-child {
    margin: 0px;
}

.build-arrows {
    align-items: center;
    display: flex;
    justify-content: end;
    margin-top: 50px;
    position: relative;
    z-index: 1;
    width: 50%;
    float: right;
}

.build-slider .slick-dots {
    text-align: left;
    bottom: -80px;
}

.buildslide-image {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(#ffffff, #ffffff);
    background: linear-gradient(to right, #000000, rgba(0, 0, 0, 50%));
    min-height: 450px;

}

.home-counter {
    position: relative;
}

.home-counter .row {
    border-top: 1px solid #B7AAA4;
    border-bottom: 1px solid #B7AAA4;
}

.homecount-box {
    text-align: center;
    margin: 50px 0px;
}

.homecount-box h4,
.homecount-box h3 {
    color: #000000;
    font-size: 50px;
    margin: 0px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 10px;
}

.homecount-box label {
    font-size: .875em;
}

.homecount-box p {
    font-size: 22px;
    color: #000000;
    font-family: "Deluce";
    line-height: 1;
    margin: 0px;
    text-transform: lowercase;
    font-weight: 600;
}

.counter-vector-1,
.counter-vector-2 {
    display: inline-block;
    position: absolute;
    top: -16px;
    left: 0;
    right: 0;
    background: #fff;
    margin: 0 auto;
    text-align: center;
    width: 45px;
    height: 45px;
    padding: 0px 8px;
}

.counter-vector-1 img,
.counter-vector-2 img {
    width: 100%;
    height: auto;
}

.counter-vector-2 {
    top: auto;
    bottom: -28px;
}

.home-why {
    margin-top: 60px;
}

.home-whydetail {
    position: relative;
}

.why-slider {
    border-radius: 10px;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(#ffffff, #ffffff);
}

.why-slider::after {
    position: absolute;
    content: '';
    background: linear-gradient(to right, #000000, rgba(0, 0, 0, 0%) 90%);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.whyslide-detail {
    position: absolute;
    top: 0;
    left: 0;
    width: 32%;
    margin: 60px;
    z-index: 1;
}

.whyslide-detail .heading {
    color: #fff;
    line-height: .9;
    margin-bottom: 30px;
}

.whyslide-detail p {
    margin: 0px;
    color: #fff;
    font-weight: 300;
    text-align: justify;
}

.why-slider .slick-dots {
    text-align: left;
    bottom: 60px;
    z-index: 1;
    padding-left: 60px !important;
}

.why-slider .slick-dots li button {
    border-radius: 50%;
    transform: none;
}

.whyslide-shape {
    transform: translateY(-50%) rotate(90deg);
    position: absolute;
    right: -31px;
    top: 50%;
    z-index: 1;
    width: 120px;
}

.home-choose {
    padding: 80px 0px;
    background: url(../public/assets/home/choose-back.webp) no-repeat;
    background-size: cover;
    background-position: center;
}

.home-choose .heading {
    color: #B7AAA4;
    margin-bottom: 50px;
    text-transform: lowercase;
}

.choose-box {
    margin-top: 20px;
    text-align: center;
}

.choose-box img {
    width: 22px;
}

.choose-box p {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: #ffffff;
    margin: 13px 0px 0px;
    cursor: pointer;
}

.home-expert {
    margin: 60px 0px;
}

.expertise-video {
    overflow: hidden;
    border-radius: 10px;
    margin-right: 60px;
    position: relative;
}

.expertise-video .img-responsive,
.expertise-video video,
.expertise-video iframe {
    height: 484px;
    object-fit: cover;
    width: 100%;
}

.play-expert {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid #fff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: 18px;
    backdrop-filter: blur(4px);
    background-color: rgb(255 255 255 / 5%);
}

.play-expert:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    background: rgb(255 255 255 / 60%);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.expertise-detail .heading {
    font-size: 54px;
    text-transform: lowercase;
}

.expertise-detail img {
    width: 25px;
    margin-bottom: 5px;
}

.expertise-detail p {
    margin: 40px 0px 0px;
    color: rgb(0 0 0 / 60%);
    text-align: justify;
}

.execute-container {
    background: #000000;
    border-radius: 15px;
    padding: 40px;
    overflow: hidden;
}

.execute-textbox {
    margin-right: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.execute-textbox .heading {
    color: #fff;
    font-size: 52px;
    text-transform: lowercase;
}

.execute-textbox img {
    width: 18px;
    margin-bottom: 5px;
}

.execute-process {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.execute-process div {
    border-top: 1px solid rgb(183 169 165 / 50%);
    margin-top: 25px;
    width: 47.5%;
}

.execute-process p {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: #ffffff;
    margin: 0;
}

.execute-process span {
    font-size: 13px;
    letter-spacing: 1.2px;
    color: #B7A9A5;
    font-weight: 500;
    display: inline-block;
    margin: 8px 0px 3px;
}

.mamu-videoarea {
    margin: 60px 0px;
}

.manu-imageslider {
    background: #EEEBE9;
    border-radius: 10px;
    padding: 30px 0px;
    margin: 0px !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.home-facility {
    margin-top: 60px;
    overflow: hidden;
}

.home-facility .heading {
    text-transform: lowercase;
    font-size: 54px;
}

.homefacility-head img {
    width: 22px;
    margin-bottom: 10px;
}

.home-facility p {
    margin-bottom: 0px;
    color: rgb(0 0 0 / 60%);
    text-align: justify;
}

.facility-slider {
    margin: 60px 0px;
}

.facility-slider .slick-slider .slick-slide div {
    margin: 0px 2px;
}

.facility-slider .slick-slider .slick-slide .img-responsive {
    border-radius: 10px;
}

.home-quality {
    background: #B7AAA4;
    padding: 60px 0px;
}

.quality-grid {
    padding: 0px 24px !important;
}

.quality-row {
    background: #000000;
    border-radius: 15px;
    margin: 0px;
    padding: 40px;
    overflow: hidden;
}

.homequality-haed {
    text-align: center;
}

.homequality-haed .heading {
    color: #B7AAA4;
    font-size: 54px;
}

.homequality-haed img {
    width: 38px;
    margin: 30px 0px 40px;
}

.quality-box {
    background: #fff;
    border-radius: 10px;
    padding: 25px;
    min-height: 248px;
}

.quality-box h4 {
    color: #B7AAA4;
    font-family: "Deluce";
    line-height: 1;
    font-size: 45px;
    margin: 0px;
}

.quality-box span {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.2px;
    display: block;
    color: #000;
    margin: 14px 0px 10px;
}

.quality-box p {
    margin: 0px;
    color: rgb(0 0 0 / 60%);
    text-align: justify;
}

.home-project {
    margin: 60px 0px;
}

.vector-heading {
    text-align: center;
}

.vector-heading .heading {
    margin: 35px 0px 50px;
}

.vector-heading img {
    width: 38px;
}

.project-box {
    background: #F4F0EE;
    margin-bottom: 50px;
    border-radius: 30px;
    text-align: center;
}

.project-box a {
    padding: 50px 70px 0px;
    display: block;
}

.project-box .heading {
    font-size: 42px;
    margin-bottom: 25px;
    text-transform: lowercase;
}

.project-box p {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 0px;
    color: #000;
}

.projectbox-slider {
    position: relative;
    margin-top: 50px;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    -webkit-mask-image: -webkit-radial-gradient(#ffffff, #ffffff);
}

.projectbox-slider .pro-curve {
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    margin: 0 auto;
    z-index: 1;
    width: 110px;
}

.projectbox-slider .slick-slider .slick-dots {
    bottom: 30px;
}

.home-testimonial {
    background: linear-gradient(to top, #E0DBD9, rgb(255 255 255 / 0%) 100%);
    padding: 60px 0px;
}

.testi-vector {
    width: 38px;
    margin: 0 auto 50px;
    display: block;
}

.testi-image {
    padding-right: 0px !important;
}

.testi-image img {
    border-radius: 10px 50px 0px 10px;
    position: relative;
    z-index: 1;
}

.no-leftpadd {
    padding-left: 0px !important;
}

.no-rightpadd {
    padding-right: 0px !important;
}

.testimain-box {
    background: #fff;
    border: 1px solid #B7AAA4;
    border-radius: 50px 10px 10px 0px;
    padding: 50px;
}

.testimain-box .heading {
    font-size: 42px;
    display: flex;
    align-items: start;
    position: relative;
}

.testimain-box .heading img {
    width: 26px;
}

.testimain-box .heading .quote-rotate {
    position: absolute;
    right: 0;
    bottom: -3px;
    transform: rotate(180deg);
}

.testimain-box .heading div {
    margin: 0px 33px 0px 8px;
}

.testimain-box p {
    margin: 20px 0px;
    text-align: justify;
}

.testimain-box span {
    font-size: 16px;
    font-weight: 500;
    display: block;
    color: #000;
}

.testimain-box small {
    color: #B7AAA4;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 500;
    display: block;
    border-bottom: 1px solid #B7AAA4;
    padding-bottom: 12px;
    margin-top: 3px;
}

.home-quote {
    padding: 160px 0px 80px;
    background: url(../public/assets/home/quote-back.webp) no-repeat;
    background-size: cover;
    background-position: center;
    text-align: center;
    overflow: hidden;
}

.home-quote img {
    width: 50px;
    margin-bottom: 15px;
}

.home-quote h4 {
    color: #fff;
    font-size: 28px;
    margin: 0px;
}

.home-quote label {
    display: block;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    color: #ffffff;
    margin: 20px 0px 60px;
}

.home-process {
    background: #F2F0EF;
    padding: 60px 0px;
}

.process-row {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 90%;
}

.process-box {
    background: #fff;
    border-radius: 10px;
    padding: 25px;
    width: 22%;
    position: relative;
}

.process-box p {
    color: rgb(0 0 0 / 60%);
    margin: 0;
    text-align: justify;
}

.process-box span {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.2px;
    display: block;
    color: #000;
    margin: 22px 0px 0px;
}

.process-box img {
    width: 40px;
}

.process-top .process-box:after {
    content: "";
    position: absolute;
    bottom: -43.5px;
    right: 0;
    margin: 0 auto;
    left: 0;
    width: 8px;
    border-style: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #B8ABA5 transparent;
}

.process-top .process-box:before {
    content: "";
    position: absolute;
    bottom: -43px;
    background: #B8ABA5;
    height: 43px;
    width: 1px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.process-bottom .process-box:after {
    content: "";
    position: absolute;
    top: -43.5px;
    right: 0;
    margin: 0 auto;
    left: 0;
    width: 8px;
    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: #B8ABA5 transparent transparent transparent;
}

.process-bottom .process-box:before {
    content: "";
    position: absolute;
    top: -43px;
    background: #B8ABA5;
    height: 43px;
    width: 1px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.process-bottom {
    float: right;
}

.process-divider {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 25px 0px;
    z-index: 1;
}

.process-divider::before {
    position: absolute;
    content: '';
    background: #B8ABA5;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}

.process-divider img {
    width: 32px;
    background: #F2F0EF;
}

.portfolio-slidebox {
    text-align: center;
    padding: 0px 10px;
}

.portfolio-slidebox div {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
}

.port-curve-1,
.port-curve-2 {
    position: absolute;
    left: 0;
    right: 0;
    top: -1.2px;
    margin: 0 auto;
    z-index: 1;
    width: 80px;
}

.port-curve-2 {
    top: auto;
    bottom: -1.3px;
    transform: rotate(180deg);
}

.portfolio-slidebox div .img-responsive {
    transition: transform 0.4s ease-in-out 0s;
}

.portfolio-slidebox:hover div .img-responsive {
    transform: scale(1.03);
}

.portfolio-slidebox h4 {
    border-top: 1.4px solid #B7A9A5;
    margin: 19px 0px 10px;
    padding-top: 12px;
    color: #000000;
    font-size: 20px;
    text-transform: capitalize;
}

.portfolio-slidebox span {
    display: block;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.2px;
    color: #B7AAA4;
}

.portfo-arrows {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    position: relative;
    z-index: 1;
}

.home-story {
    background: linear-gradient(to top, #B7AAA4, rgb(255 255 255 / 0%) 90%);
    padding-bottom: 60px;
}

.storyarea-head {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.storyarea-head .heading {
    color: #B7AAA4;
    font-size: 54px;
    text-transform: lowercase;
}

.storyarea-head img {
    width: 20px;
}

.storyarea-point {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.storyarea-point div {
    width: 48%;
}

.storyarea-point div:nth-child(1),
.storyarea-point div:nth-child(2) {
    margin-bottom: 10px;
}

.storyarea-point div img {
    width: 11px;
    margin-bottom: 2px;
}

.storyarea-point div p {
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: #ffffff;
    margin: 0;
}

.story-slider {
    margin-top: 30px;
}

.story-slidebox {
    text-align: center;
    padding: 0px 10px;
}

.story-slidebox .img-responsive {
    border-radius: 12px 12px 25px 25px;
    border: 1.4px solid #B7AAA4;
    width: 100%;
}

.story-slidebox div {
    background: #fff;
    padding: 30px;
    border-radius: 25px 25px 12px 12px;
    box-shadow: 0px 0px 0px 1px #B7AAA4 inset;
}

.story-slidebox div p {
    margin-bottom: 10px;
    padding-bottom: 12px;
    padding-right: 12px;
    border-bottom: 1px solid #b7aaa4;
    text-align: justify;
    height: 180px;
    overflow-y: auto;
}

.story-slidebox div p::-webkit-scrollbar {
width: 3px;
background: #F2F0EF;
}

.story-slidebox div p::-webkit-scrollbar-track {
-webkit-box-shadow: none;
}

.story-slidebox div p::-webkit-scrollbar-thumb {
background-color: #b7aaa4;
outline: none;
border-radius: 50px;
}

.story-slidebox div span {
    color: #000000;
    font-size: 17px;
    text-transform: uppercase;
    display: block;
    font-weight: 500;
    letter-spacing: 1px;
}

.story-slidebox div small {
    color: #B7AAA4;
    font-weight: 500;
}

.ceo-message {
    margin: 60px 0px;
    overflow: hidden;
}

.ceomessage-box {
    padding: 0px 60px;
    position: relative;
    text-align: center;
    border-radius: 10px;
}

.ceomessage-box h4 {
    color: #000;
    font-size: 32px;
    margin-bottom: 18px;
}

.ceomessage-box .img-responsive {
    width: 50px;
    margin: 0px auto 18px;
}

.ceomessage-box span {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    color: #B7AAA4;
}

.message-shape-1 {
    transform: translateY(-50%) rotate(-90deg);
    position: absolute;
    left: -31px;
    top: 50%;
    z-index: 1;
    width: 120px;
}

.message-shape-2 {
    transform: translateY(-50%) rotate(90deg);
    position: absolute;
    right: -31px;
    top: 50%;
    z-index: 1;
    width: 120px;
}

.home-service {
    margin: 50px 0px 60px;
}

.home-service .heading {
    margin-bottom: 50px;
}

.service-slider .slick-slide {
    padding: 0px 10px;
}

.service-box {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
}

.service-box:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, transparent, #000000 90%);
    z-index: 1;
}

.service-box div {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 15px 0px 20px;
    width: 88%;
    border-top: 1.4px solid #fff;
    text-align: center;
    z-index: 1;
}

.service-box div h4 {
    color: #fff;
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.service-box div span {
    display: inline-block;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    color: #B7AAA4;
    transition: all 0.2s ease-In;
}

.service-box div span:hover {
    letter-spacing: 3px;
}

.service-box .img-responsive {
    transition: transform 0.4s ease-in-out 0s;
    width: 100%;
}

.service-box:hover .img-responsive {
    transform: scale(1.05);
}

.service-curve {
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    margin: 0 auto;
    z-index: 1;
    width: 80px;
    filter: brightness(0) invert(1);
}

.servicedetail-curve {
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    margin: 0 auto;
    z-index: 1;
    width: 80px;
}

.home-media {
    margin: 60px 0px;
}

.homemedia-box {
    background: #F2F0EF;
    padding: 80px 60px 50px;
    position: relative;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
}

.media-shape-1 {
    transform: translateY(-50%) rotate(-90deg);
    position: absolute;
    left: -31px;
    top: 50%;
    z-index: 1;
    width: 120px;
}

.media-shape-2 {
    transform: translateY(-50%) rotate(90deg);
    position: absolute;
    right: -31px;
    top: 50%;
    z-index: 1;
    width: 120px;
}

.homemedia-box .shape-divider {
    margin: 40px 0px;
}

.shape-divider {
    position: relative;
    z-index: 1;
}

.shape-divider::before {
    position: absolute;
    content: '';
    background: #B7AAA4;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}

.shape-divider::after {
    position: absolute;
    content: '';
    background: #F2F0EF;
    width: 48px;
    height: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
}

.shape-divider img {
    width: 32px;
}

.medialogo-slider {
    margin: 0px 60px;
}

.medialogo-slider .slick-list {
    overflow: visible;
}

.medialogo-slider .slick-slide div {
    align-items: center;
    display: flex;
    height: 50px;
    justify-content: center;
    padding: 0px 20px;
}

.medialogo-slider .slick-slide div a {
    outline: none;
}

.medialogo-slider .slick-slide div img {
    display: block;
    height: auto;
    margin: 0 auto;
    max-width: 100%;
    width: auto !important;
}

.get-touch {
    padding: 80px 0px;
    background: url(../public/assets/home/gettouch-back.webp) no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.gettouch-text {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.gettouch-text .heading {
    color: #fff;
}

.gettouch-text p {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: #ffffff;
    margin: 0;
    display: flex;
    align-items: center;
}

.gettouch-text p img {
    width: 18px;
    margin-right: 6px;
}

.gettouch-form {
    padding: 80px 22px 50px;
    position: relative;
    z-index: 1;
    margin-left: 30px;
}

.gettouch-form::before {
    position: absolute;
    content: '';
    background: #fff;
    border-radius: 12px 50px 0px 12px;
    width: 50.1%;
    height: 100%;
    z-index: -1;
    left: 0;
    top: 0;
}

.gettouch-form::after {
    position: absolute;
    content: '';
    background: #fff;
    border-radius: 50px 12px 12px 0px;
    width: 50.1%;
    height: 100%;
    z-index: -1;
    right: 0;
    top: 0;
}

.form-feild {
    margin-bottom: 20px;
}

.gettouch-form .form-control {
    box-shadow: none;
    background: none;
    border: 0px;
    border-bottom: 1px solid rgb(0 0 0 / 12%);
    border-radius: 0px;
    font-size: 13px;
    font-weight: 500;
    color: #000;
    padding: .375rem 0rem;
    padding-left: 5px;
}

.gettouch-form select.form-control{
    color: rgb(0 0 0 / 60%);
}

.gettouch-form select.form-control option{
    color: rgb(0 0 0 / 90%);
}

.gettouch-form .form-control:focus {
    box-shadow: none;
    background: none;
    border-color: rgb(0 0 0 / 12%);
}

.error-text {
    font-size: 11px;
    color: #c90000;
    margin: 7px 0px 0px;
    line-height: 1.4;
    text-align: left;
}

.footer {
    background: #000000;
    padding: 70px 0px;
}

.footer-links {
    display: flex;
    justify-content: space-between;
}

.footlink-1 li {
    margin-right: 15px;
    display: inline-block;
}

.footlink-1 li:last-child {
    margin-right: 0px;
}

.footlink-1 li a {
    font-size: 12px;
    text-transform: uppercase;
    transition: all 0.2s ease-In;
    font-weight: 600;
    letter-spacing: 1px;
    color: #ffffff;
}

.footlink-1 li a:hover {
    color: #B7A9A5;
}

.footlink-2 li {
    margin-left: 15px;
    display: inline-block;
}

.footlink-2 li:first-child {
    margin-left: 0px;
}

.footlink-2 li a {
    font-size: 12px;
    text-transform: uppercase;
    transition: all 0.2s ease-In;
    font-weight: 500;
    letter-spacing: 2px;
    color: #ffffff;
    text-decoration: underline !important;
}

.footlink-2 li a:hover {
    color: #B7A9A5;
}

.footer-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0px;
}

.footer-logo .img-responsive {
    width: 270px;
}

.subscribe-form {
    width: 350px;
}

.subscribe-form p {
    color: #ffffff;
    font-family: Gilroy;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 8px;
}

.subscribe-form div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgb(255 255 255 / 10%);
    border: 1px solid rgb(255 255 255 / 20%);
    padding: 5px;
    border-radius: 4px;
}

.subscribe-form div .form-control {
    border: 0px;
    box-shadow: none;
    background: none;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    width: 70%;
}

.subscribe-form div .form-control::placeholder {
    color: #fff;
}

.subscribe-form div .form-control::-ms-input-placeholder {
    color: #fff;
}

.social-copywrite {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-social li {
    margin-right: 15px;
    display: inline-block;
}

.footer-social li:last-child {
    margin-right: 0px;
}

.footer-social li a {
    font-size: 12px;
    text-transform: uppercase;
    transition: all 0.2s ease-In;
    font-weight: 500;
    letter-spacing: 1px;
    color: #ffffff;
}

.footer-social li a:hover {
    color: #B7A9A5;
}

.social-copywrite p {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: #ffffff;
    margin: 0px;
}

.mobile-footersocial {
    display: none;
}

.footer-term {
    display: none;
    text-align: center;
    border-top: 1px solid rgb(255 255 255 / 20%);
    width: 100%;
    padding-top: 13px !important;
    margin: 18px 0px 22px !important;
}

.footer-term li:first-child {
    margin-bottom: 5px;
}

.footer-term li a {
    font-size: 12px;
    text-transform: uppercase;
    transition: all 0.2s ease-In;
    font-weight: 500;
    letter-spacing: 2px;
    color: #ffffff;
    text-decoration: underline !important;
}

.footer-term li a:hover {
    color: #B7A9A5;
}

.menubar-area {
    width: 100%;
    height: 100%;
    background: #000000;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    padding: 30px;
    overflow-y: scroll;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.menubar-area::-webkit-scrollbar {
    display: none;
}

.menubar-area.close-menu {
    transform: translateX(100%);
}

.menubar-area.open-menu {
    transform: translateX(0px);
    opacity: 1;
}

.menubar-area .menu-close-btn {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease-in;
    color: #fff;
    line-height: 1;
}

.menu-close-btn:hover {
    color: #B7AAA4;
}

.menu-toggel-items li {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(255 255 255 / 30%);
}

.menu-toggel-items li:last-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: 0px;
}

.menu-toggel-items li a {
    font-size: 14px;
    text-transform: uppercase;
    transition: all 0.2s ease-In;
    font-weight: 600;
    letter-spacing: 1px;
    color: #ffffff;
}

.menu-toggel-items li a:hover {
    color: #B7AAA4;
}

.mobilemenu-cta {
    margin-top: 20px;
}

.mobilemenu-cta {
    margin-top: 20px;
}

.mobilemenu-cta .default-btn {
    display: block;
}

.mobile-slidelink a {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobile-slidelink a label {
    font-size: 18px;
    line-height: 1;
}

.submenu {
    overflow: hidden;
    max-height: 0;
    transition: all 0.4s ease;
    padding-left: 0;
    margin: 0;
}

.submenu.visible {
    max-height: 500px;
}

.submenu a {
    display: block;
    margin-top: 8px;
}

.submenu a:first-child {
    margin-top: 15px;
}

.sub-header {
    position: relative;
}

.sub-header:before {
    position: absolute;
    content: '';
    background: #000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: .2;
}

.sub-header .about-banner-wrap {
    min-height: 383px;
    background: linear-gradient(to right, #000, rgb(0,0,0,0.5));
}

.sub-header .about-banner-wrap img{
    min-height: 383px;
    object-fit: cover;
}

.sub-header .img-responsive {
    width: 100%;
}

.subheader-text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 60px;
    text-align: center;
    padding: 0px 15px;
}

.sub-header.project-sub{
    min-height: 380px;
    width: 100%;
    background: linear-gradient(to right, #000, rgb(0,0,0,0.5));
}

.subheader-text .heading {
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.page-filter {
    padding: 20px 0px;
    border-bottom: 1px solid #000;
    min-height: 10vh;
}

.page-filter ul {
    text-align: center;
}

.page-filter ul li {
    display: inline-block;
    margin-right: 15px;
}

.page-filter ul li:last-child {
    margin-right: 0px;
}

.page-filter ul li a {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    display: block;
    color: #000;
    padding: 8px 15px;
    border-radius: 20px;
    transition: all 0.2s ease;
}

.page-filter ul li a:hover {
    background: rgb(183 170 165 / 10%);
    color: #B7AAA5;
}

.page-filter ul li a.active {
    background: rgb(183 170 165 / 10%);
    color: #B7AAA5;
}

.filter-dropdown {
    display: none;
}

.filter-dropdown .form-control {
    box-shadow: none;
    border: 1px solid rgb(0 0 0 / 15%);
    font-size: 14px;
    font-weight: 500;
    color: #000;
    width: 90%;
    margin: 0 auto;
    text-transform: capitalize;
    height: 45px;
    -webkit-appearance: auto;
    appearance: auto;
    background: rgb(183 169 165 / 20%);
    border-color: #B7A9A5;
}

.filter-dropdown .form-control:focus {
    box-shadow: none;
    background: rgb(183 169 165 / 20%);
    border-color: #B7A9A5;
}

.filter-dropdown select option {
    background: #fff;
}

.filter-dropdown span {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    color: #000000;
    margin-bottom: 10px;
    display: block;
    text-align: center;
}

.faq-page {
    background: linear-gradient(to top, #F2F0EF, rgb(255 255 255 / 0%) 100%);
    padding-bottom: 60px;
    margin-top: 50px;
    min-height: 29vh;
    width: 100%;
}

.faq-accord .card {
    border: 0px;
    border-radius: 0px;
    background: none;
}

.faq-accord .card-header {
    padding: 0px;
    background-color: transparent;
    border: 0px;
    border-bottom: 1px solid #E0E0E0;
}

.faq-accord .accordion-item {
    background: none;
    border: 0px;
}

.faq-accord .accordion-body {
    padding: 0px 0px 20px;
}

.faq-accord .accordion-body p {
    margin: 0px;
    color: rgb(41 41 29 / 60%);
    line-height: 1.7;
    text-align: justify;
}

.faq-accord .accordion-button {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 20px 0px;
    color: #29291D !important;
    font-size: 17px;
    font-weight: 600;
    justify-content: space-between;
}

.faq-accord .accordion-button span {
    font-size: 20px;
    line-height: 1;
    margin-left: 12px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.faq-accord .accordion-button label {
    cursor: pointer;
    width: 90%;
}

.faq-accord .accordion-button::after {
    display: none;
}

.faq-leftside {
    padding-right: 30px !important;
}

.faq-rightside {
    padding-left: 30px !important;
}

.faq-more {
    text-align: center;
    margin-top: 30px;
    display: none;
}

.project-listing {
    margin-bottom: 60px;
    min-height: 70vh;
    width: 100%;
}

.projectlist-box {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 30px;
}

.projectlist-box a {
    display: block;
    position: relative;
}

.projectlist-box a:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, transparent, rgb(0 0 0 / 50%) 100%);
    z-index: 1;
}

.projectlist-box div {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 40px;
    width: 100%;
    z-index: 1;
    text-align: center;
}

.projectlist-box div h4,
.projectbig-box h4 {
    color: #fff;
    font-size: 54px;
    margin-bottom: 12px;
    font-family: "Deluce";
    line-height: 1;
    text-transform: lowercase;
}

.projectlist-box div span,
.projectbig-box span {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.5px;
    color: #ffffff;
    position: relative;
    display: flex;
}

.projectlist-box div span:before,
.projectlist-box div span:after,
.projectbig-box span:before,
.projectbig-box span:after {
    content: '';
    color: white;
    flex: 1;
    border-bottom: 1px solid;
    margin: auto 0.6em;
}

.projectlist-box div span small,
.projectbig-box span small {
    font-size: unset;
}

.projectlist-box div span small strong,
.projectbig-box span small strong {
    margin: 0px 4px;
}

.small-projectlist div {
    text-align: left;
}

.small-projectlist.projectlist-box div span:before {
    display: none;
}

.small-projectlist.projectlist-box{
    min-height: 550px;
    width: 100%;
    background: linear-gradient(to right, #000, rgb(0, 0, 0, 0.5));
}

.small-projectlist .img-responsive {
    object-fit: cover;
}

.projectlist-box .img-responsive {
    transition: transform 0.4s ease-in-out 0s;
    width: 100%;
}

.projectlist-box:hover .img-responsive {
    transform: scale(1.05);
}

.projectlist-curve {
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    margin: 0 auto;
    z-index: 1;
    width: 110px;
}

.projectlist-more {
    display: none;
    text-align: center;
}

.projectdetail-slider .slick-dots {
    z-index: 2;
    bottom: 32px;
}

.projectdetail-big {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-top: 30px;
    -webkit-mask-image: -webkit-radial-gradient(#ffffff, #ffffff);
    width: 100%;
    min-height: 550px;
    background: linear-gradient(to right, #000, rgb(0, 0, 0, 0.5));
}

.projectdetail-big a {
    display: block;
    position: relative;
}

.projectdetail-big a:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, transparent, rgb(0 0 0 / 38%) 100%);
    z-index: 1;
}

.projectbig-box {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 40px 40px 70px;
    width: 100%;
    z-index: 1;
    text-align: center;
}

.projectbig-curve {
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    margin: 0 auto;
    z-index: 1;
    width: 110px;
}

.media-page {
    margin: 40px 0px 60px;
    background: #fff;
    min-height: 50vh;
}

.medialist-box {
    margin-bottom: 24px;
}

.medialist-box small{
    width: 100%;
    min-height: 177px;
    background: linear-gradient(to right, #000, rgb(0, 0, 0, 0.5));
    display: block;
    border-radius: 10px 10px 25px 25px;
}

.medialist-box div label{
    width: 100%;
    min-height: 25px;
    background: #fff;
    display: block;
}

.medialist-box .img-responsive {
    border-radius: 10px 10px 25px 25px;
    border: 1.4px solid #DADADA;
    width: 100%;
}

.medialist-box div {
    background: #fff;
    padding: 20px;
    border-radius: 25px 25px 10px 10px;
    border: 1px solid #DADADA;
    transition: all 0.2s ease-In;
}

.medialist-box div img {
    width: 85px;
}

.medialist-box div span {
    color: #000000;
    font-size: 17px;
    text-transform: capitalize;
    display: block;
    font-weight: 500;
    letter-spacing: .2px;
    line-height: 1.2;
    margin: 15px 0px 14px;
    min-height: 40px;
}

.medialist-box div a {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #B8ABA5;
    transition: all 0.2s ease-In;
    text-decoration: underline !important;
}

.medialist-box div a:hover {
    color: #000;
}

.medialist-box:hover div {
    box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.05);
}

.mediapost-more {
    text-align: center;
    margin-top: 30px !important;
}

.servicepage-detail {
    background: #F3F1F0;
    padding: 60px 0px;
}

.other-service {
    background: linear-gradient(to bottom, #F3F1F0, rgb(255 255 255 / 0%) 100%);
    padding: 60px 0px;
}

.otherproj-arrows {
    align-items: center;
    display: none;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.servicepage-tag {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: #000000;
    margin: 0;
    display: flex;
    align-items: center;
    margin-left: 70px;
    margin-bottom: 10px;
}

.servicepage-tag img {
    width: 18px;
    margin-right: 6px;
}

.servicedetail-slider .buildslide-box .heading {
    font-size: 45px;
}

.servicepage-detail .build-arrows .default-arrowleft,
.servicepage-detail .build-arrows .default-arrowright {
    background: #fff;
}

.servicepage-detail .build-arrows .default-arrowleft:hover,
.servicepage-detail .build-arrows .default-arrowright:hover {
    background: #B3A693;
}

.serviceslide-image {
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    -webkit-mask-image: -webkit-radial-gradient(#ffffff, #ffffff);
}

.servicepage-head {
    background: linear-gradient(to bottom, #F2F0EF, rgb(255 255 255 / 0%) 100%);
    padding: 60px 0px;
    text-align: center;
    min-height: 220px;
}

.servicepage-head p {
    color: rgb(0 0 0 / 60%);
}

.servicepage-head a {
    font-size: 14px;
    color: #B8ABA5;
    font-weight: 600;
    text-decoration: underline !important;
    letter-spacing: .3px;
    transition: all 0.2s ease-In;
}

.servicepage-head a:hover {
    color: #000;
}

.servicepage-slider {
    margin: 0px;
    background: #F3F1F0;
    min-height: 360px;
}

.service-process {
    background: linear-gradient(to top, #F3F1F0, rgb(255 255 255 / 0%) 100%);
    padding: 0px;
}

.service-keypoint {
    margin: 60px 0px;
}

.keypoint-box {
    background: #F3F1F0;
    border: 1px solid #B7A9A5;
    border-radius: 10px;
    padding: 25px;
    text-align: center;
    min-height: 237px;
}

.keypoint-box span {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1.2px;
    display: block;
    color: #000;
    margin-bottom: 15px;
}

.keypoint-box p {
    color: rgb(0 0 0 / 60%);
    margin: 0px;
    text-align: justify;
}

.contactpage-form {
    padding: 60px 0px;
    position: relative;
}

.contactpage-form::before {
    content: '';
    position: absolute;
    background: #B7A9A5;
    height: 1px;
    width: 1140px;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.contact-form {
    padding-left: 30px;
}

.contact-form .heading {
    margin-bottom: 30px;
}

.contact-form .form-control {
    box-shadow: none;
    background: none;
    border: 0px;
    border-bottom: 1px solid rgb(0 0 0 / 12%);
    border-radius: 0px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: .375rem 0rem;
    padding-left: 5px;
}

.contact-form .form-control:focus {
    box-shadow: none;
    background: none;
    border-color: rgb(0 0 0 / 12%);
}

.contact-form .form-control::placeholder {
    color: #000;
}

.contact-form .form-control::-ms-input-placeholder {
    color: #000;
}

.contact-form .default-btn {
    margin-top: 18px;
}

.contact-map iframe {
    border: 2px solid #B7A9A5;
    border-radius: 10px;
    display: block;
    height: 290px;
}

.contact-address {
    display: flex;
    justify-content: space-between;
}

.contact-address div {
    margin-top: 25px;
    width: 48%;
}

.contact-address div span {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .5px;
    color: #B7A9A5;
    display: block;
}

.contact-address div p {
    color: #000;
    text-transform: uppercase;
    font-size: 13px;
    margin: 8px 0px;
}

.contact-address div a {
    font-size: 15px;
    transition: all 0.2s ease-In;
    color: #000;
}

.contact-address div a:hover {
    color: #B7A9A5;
}

.contactpage-career {
    margin-bottom: 60px;
    overflow: hidden;
}

.vendor-career {
    display: flex;
    justify-content: space-between;
}

.contact-career {
    background: #F2F0EF;
    border-radius: 10px;
    padding: 60px 40px 40px;
    position: relative;
    margin-top: 40px;
    width: 48.8%;
}

.contact-career::before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: -3px;
    margin: 0 auto;
    z-index: 2;
    width: 13px;
    height: 13px;
    background: #B7AAA4;
    transform: rotate(45deg);
}

.contact-career .vendor-shape {
    position: absolute;
    left: 0;
    right: 0;
    top: -1px;
    margin: 0 auto;
    z-index: 1;
    width: 80px;
}

.contact-career h4 {
    color: #000;
    font-size: 28px;
    margin: 0;
}

.contact-career p {
    margin: 20px 0px;
    color: rgb(0 0 0 / 60%);
    text-align: justify;
}

.contactjob-list {
    border: 1px solid rgb(0 0 0 / 16%);
    background: #fff;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease;
}

.contactjob-list span {
    color: #000;
    font-weight: 500;
    font-size: 15px;
    width: 68%;
}

.contactjob-list a {
    color: #B7A9A5;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.2s ease;
}

.contactjob-list:hover {
    box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.05);
}

.contactjob-list a:hover {
    color: #000;
}

.career-page {
    margin: 60px 0px;
    width: 100%;
    min-height: 25vh;
}

.careerpage-head {
    padding-right: 50px;
    position: sticky;
    top: 100px;
}

.careerpage-head .heading {
    margin: 20px 0px 30px;
}

.careerpage-head img {
    width: 32px;
}

.careerpage-head p {
    margin-bottom: 0px;
    color: rgb(0 0 0 / 60%);
    text-align: justify;
}

.career-listing p {
    color: #000;
    font-size: 22px;
    margin-bottom: 15px;
    padding-bottom: 10px;
    font-weight: 500;
    border-bottom: 1px solid #B7A9A5;
}

.careerjob-list {
    border: 1px solid rgb(0 0 0 / 16%);
    background: #fff;
    border-radius: 5px;
    padding: 18px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.2s ease;
}

.careerjob-list span {
    color: #000;
    font-weight: 500;
    font-size: 15px;
    width: 80%;
}

.careerjob-list a {
    color: #B7A9A5;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    transition: all 0.2s ease;
}

.careerjob-list:hover {
    box-shadow: 0 2px 16px 4px rgba(40, 44, 63, 0.05);
}

.careerjob-list a:hover {
    color: #000;
}

.pop-close {
    box-shadow: none;
    border: 1px solid #DCDCDC;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: #000;
    opacity: 1;
    position: absolute;
    right: 30px;
    top: 30px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pop-close img {
    width: 10px;
}

.pop-close:hover {
    background: #B3A693;
}

.career-popup {
    padding: 0px !important;
}

.career-popup .modal-dialog {
    width: 450px;
}

.careerpop-head {
    font-size: 20px;
    margin-bottom: 20px;
    padding-bottom: 15px;
    color: #000;
    border-bottom: 1px solid #B7A9A5;
}

.career-popup .modal-body {
    padding: 30px;
}

.careerpop-content span {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: #B7AAA4;
    margin-bottom: 6px;
}

.careerpop-content .default-btn {
    width: 100%;
}

.careerpop-content form .form-control {
    box-shadow: none;
    background: none;
    border: 0px;
    border-bottom: 1px solid rgb(0 0 0 / 12%);
    border-radius: 0px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    padding: .375rem 0rem;
    padding-left: 5px;
}


.careerpop-content form .form-control::placeholder {
    color: #000;
}

.careerpop-content form .form-control::-ms-input-placeholder {
    color: #000;
}

.careerpop-content form textarea {
    height: 90px;
}

.uploadFile {
    width: 100%;
    overflow: hidden;
    position: relative;
    height: 42px;
    border: 1px solid rgb(0 0 0 / 12%);
    background: none;
    margin-bottom: 20px;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.375rem 5px;
}

.uploadFile [type=file] {
    cursor: pointer !important;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0px;
    text-align: right;
    top: 0px;
    z-index: 1;
}

.uploadFile small {
    color: #000;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}

.job-detail {
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
    padding: 15px 0px;
    margin: 25px 0px 30px;
    display: flex;
    justify-content: space-between;
}

.job-detail small {
    font-weight: 600;
}

.aboutpage-head {
    margin: 60px 0px;
}

.abouthead-image {
    position: relative;
    padding-right: 40px;
}

.abouthead-image .img-responsive {
    border-radius: 10px;
}

.abouthead-detail p {
    margin-bottom: 0px;
    color: rgb(0 0 0 / 60%);
    text-align: justify;
}

.abouthead-detail span {
    position: relative;
    display: block;
    z-index: 1;
}

.abouthead-detail span::after {
    position: absolute;
    content: '';
    background: #B7AAA4;
    width: 92%;
    height: 1px;
    right: 0;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
}

.abouthead-detail span img {
    width: 28px;
}

.abouthead-detail .heading {
    margin: 30px 0px 40px;
}

.aboutpage-vision {
    margin: 60px 0px;
}

.vision-detail {
    padding-right: 50px;
}

.vision-detail p {
    margin: 40px 0px 50px;
    color: rgb(0 0 0 / 60%);
    text-align: justify;
}

.design-image .slick-slider {
    border-radius: 10px 40px 40px 10px;
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(#ffffff, #ffffff);
}

.aboutdesign-slider {
    background: #F2F0EF;
    border-radius: 40px 10px 10px 40px;
    padding: 40px 50px;
    height: 100%;
}

.designslide-box p {
    margin: 0;
    color: rgb(0 0 0 / 60%);
    text-align: justify;
}

.designslide-box .heading {
    margin-bottom: 20px;
    width: 53%;
}

.aboutdesi-arrows {
    align-items: center;
    display: flex;
    justify-content: end;
    margin-top: 20px;
    position: relative;
    z-index: 1;
}

.aboutdesign-slider .slick-slider .slick-dots {
    text-align: left;
    bottom: -50px;
}

.aboutdesi-arrows .default-arrowleft,
.aboutdesi-arrows .default-arrowright {
    background: #fff;
    border: 0px;
}

.aboutdesi-arrows .default-arrowleft:hover,
.aboutdesi-arrows .default-arrowright:hover {
    background: #B3A693;
}

.leader-area {
    background: linear-gradient(to bottom, #F2F0EF, rgb(255 255 255 / 0%) 100%);
    padding: 60px 0px;
}

.leadership-box {
    display: flex;
    justify-content: space-between;
}

.leadership-box .img-responsive {
    width: 50%;
    border-radius: 10px 40px 0px 10px;
    position: relative;
    z-index: 1;
}

.leadership-box div {
    width: 50%;
    background: #fff;
    border-radius: 40px 10px 10px 0px;
    border: 1px solid #B7AAA4;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.leadership-box div p {
    margin: 0px;
    text-align: justify;
}

.leadership-box div span {
    color: #B7AAA4;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    display: block;
    font-size: 22px;
    line-height: 1;
}

.leadership-box div small {
    color: #B7AAA4;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid #B7AAA4;
    margin: 10px 0px 15px;
    padding-bottom: 12px;
}

.about-team {
    margin-bottom: 45px;
}

.team-box {
    margin-bottom: 16px;
}

.team-box div {
    overflow: hidden;
    border-radius: 10px 40px 0px 10px;
}

.team-box img {
    width: 100%;
    transition: transform 0.4s ease-in-out 0s;
}

.team-box span {
    color: #000000;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    display: block;
    font-size: 20px;
    margin: 12px 0px 2px;
}

.team-box small {
    color: #B7AAA4;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid #B7AAA4;
    padding-bottom: 8px;
}

.team-box:hover div img {
    transform: scale(1.05);
}

.team-more {
    text-align: center;
    margin-top: 30px;
}

.teams-popup .modal-dialog {
    max-width: 650px;
}

.teams-popup .modal-content {
    border: 1.5px solid #B7AAA4;
    overflow: hidden;
}

.teams-popup .modal-body {
    padding: 0;
}

.teams-popup .pop-close {
    right: 10px;
    top: 10px;
}

.teampopup-box {
    display: flex;
    justify-content: space-between;
}

.teampopup-box .img-responsive {
    width: 50%;
}

.teampopup-box div {
    width: 50%;
    background: #fff;
    padding: 40px 50px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-left: 1.5px solid #B7AAA4;
}

.teampopup-box div p {
    margin: 0px;
    text-align: justify;
}

.teampopup-box div span {
    color: #B7AAA4;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    display: block;
    font-size: 24px;
    line-height: 1;
}

.teampopup-box div small {
    color: #B7AAA4;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    border-bottom: 1px solid #B7AAA4;
    margin: 10px 0px 15px;
    padding-bottom: 12px;
}

.about-service .home-service {
    margin: 60px 0px;
    background: linear-gradient(to bottom, #F3F1F0, rgb(255 255 255 / 0%) 100%);
    padding-top: 60px;
}

.about-service .home-service .service-curve {
    filter: none;
}

.mobile-team {
    display: none;
}

.mobile-team .slick-slider {
    padding-bottom: 30px;
}

.mobile-team .slick-slider .slick-slide>div {
    padding: 0px 5px;
}

.about-quote {
    overflow: hidden;
}

.aboutquote-box {
    background: #F2F0EF;
    padding: 50px 60px;
    position: relative;
    text-align: center;
    border-radius: 10px;
}

.aboutquote-box .heading {
    font-size: 38px;
    margin-bottom: 10px;
}

.aboutquote-box .img-responsive {
    width: 50px;
    margin: 0px auto 18px;
}

.aboutquote-box span {
    display: block;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    color: #B7AAA4;
}

.aboutquote-box p {
    width: 48%;
    margin: 0 auto 20px;
    font-size: 16px;
}

.aboutvideo-box {
    position: relative;
}

.aboutvideo-box .img-responsive {
    border-radius: 10px;
    width: 100%;
    object-fit: cover;
    height: 530px;
}

.aboutvideo-box video {
    width: 100%;
    height: 530px;
    object-fit: cover;
    border-radius: 10px;
}

.aboutvideo-box div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.aboutvideo-box span {
    color: #fff;
    font-size: 17px;
    text-transform: uppercase;
    display: block;
    margin-top: 12px;
}

.aboutvideo-box span br {
    display: none;
}

.about-playvideo {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #B7A9A5;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: relative;
    font-size: 22px;
}

.about-playvideo:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background: rgb(183 169 165);
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
}

.about-media {
    background: linear-gradient(to top, #F2F0EF, rgb(255 255 255 / 0%) 40%);
    padding: 30px 0px 60px;
    overflow: hidden;
}

.about-media .vector-heading .heading {
    margin: 35px 0px 20px;
}

.aboutmedia-box {
    margin-bottom: 20px;
}

.aboutmedia-box .mediabox-logo {
    background: #fff;
    padding: 20px;
    border-radius: 10px 10px 25px 25px;
    border: 1px solid #DADADA;
    text-align: center;
}

.aboutmedia-box .mediabox-detail {
    background: #fff;
    padding: 20px;
    border-radius: 25px 25px 10px 10px;
    border: 1px solid #DADADA;
    transition: all 0.2s ease-In;
}

.aboutmedia-box img {
    width: 100px;
    margin: 0 auto;
}

.aboutmedia-box div span {
    color: #000000;
    font-size: 16px;
    text-transform: uppercase;
    display: block;
    font-weight: 500;
    letter-spacing: .5px;
    line-height: 1.4;
    margin-bottom: 14px;
}

.aboutmedia-box div p {
    color: rgb(0 0 0 / 60%);
    font-size: 13px;
    margin-bottom: 30px;
    text-align: justify;
}

.aboutmedia-box div a {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #B8ABA5;
    transition: all 0.2s ease-In;
    text-decoration: underline !important;
}

.aboutmedia-box div a:hover {
    color: #000;
}

.aboutmedia-filter {
    text-align: center;
    margin-bottom: 50px !important;
}

.aboutmedia-filter li {
    display: inline-block;
    margin: 0px 15px;
}

.aboutmedia-filter li a {
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    display: block;
    color: #000;
    transition: all 0.2s ease;
    border-bottom: 1px solid #fff;
}

.aboutmedia-filter li a:hover {
    color: #B7AAA4;
    border-color: #B7AAA4;
}

.aboutmedia-filter li a.active {
    color: #B7AAA4;
    border-color: #B7AAA4;
}

.mobile-aboutmedia {
    display: none;
}

.aboutpage-quality {
    margin-bottom: 60px;
}

.aboutpage-quality .home-quality {
    background: none;
    padding: 0;
}

.aboutpage-quality .quality-box{
    min-height: 314px;
}

.about-faq {
    padding-bottom: 60px;
    background: #F2F0EF;
}

.about-history {
    background: #F2F0EF;
    padding-top: 60px;
    overflow: hidden;
}

.about-history .center-heading {
    margin-bottom: 70px;
}

.history-box {
    background: #fff;
    border-radius: 10px;
    padding: 22px;
    position: relative;
    margin-top: 40px;
    min-height: 204px;
}

.history-box:before {
    content: "";
    position: absolute;
    top: -40px;
    background: #B8ABA5;
    height: 40px;
    width: 1px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.history-box:after {
    content: "";
    position: absolute;
    top: -40px;
    right: 0;
    margin: 0 auto;
    left: 0;
    width: 8px;
    border-style: solid;
    border-width: 7px 7px 0 7px;
    border-color: #B8ABA5 transparent transparent transparent;
}

.history-box p {
    color: rgb(0 0 0 / 60%);
    margin: 0;
    text-align: justify;
}

.history-box span {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .8px;
    display: block;
    color: #000;
    margin-bottom: 10px;
}

.history-box hr {
    border-color: #B8ABA5;
    position: absolute;
    top: -56px;
    left: 0;
    right: 0;
    width: 110%;
    opacity: 1;
}

.history-vector {
    width: 32px;
    background: #F2F0EF;
    margin-bottom: -22px;
    position: relative;
    z-index: 1;
}

.history-slider {
    margin-right: -220px;
}

.history-slider .slick-slide>div {
    padding: 0px 8px;
}

.mobile-abouthistory {
    display: none;
}

.mobile-abouthistory {
    position: relative;
}

.mobile-abouthistory .process-top {
    padding-top: 22px;
    padding-bottom: 22px;
}

.mobile-abouthistory .process-box span {
    margin: 0px 0px 10px;
}

.partner-divider {
    margin: 30px 0px;
    position: relative;
    z-index: 1;
    text-align: center;
}

.partner-divider::before {
    position: absolute;
    content: '';
    background: #B7AAA4;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}

.partner-divider::after {
    position: absolute;
    content: '';
    background: #ffffff;
    width: 48px;
    height: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: -1;
}

.partner-divider img {
    width: 32px;
}

.about-partner {
    margin-bottom: 30px;
}

.fliplogo-slider {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
}

.fliplogo-card {
    position: relative;
    height: 70px;
    width: 15%;
}

.flip-container {
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    -ms-perspective: 1000px;
    perspective: 1000px;
    display: block;
    width: 100%;
    height: 100%;
}

.flip-container img {
    display: block;
    max-width: 100%;
    height: auto;
    width: 80%;
    margin: 0 auto;
}

.front,
.back {
    -webkit-animation-duration: 9s;
    -moz-animation-duration: 9s;
    -ms-animation-duration: 9s;
    animation-duration: 9s;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    -moz-animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    -ms-animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.front {
    -webkit-animation-name: cardFlipFront;
    -moz-animation-name: cardFlipFront;
    -ms-animation-name: cardFlipFront;
    animation-name: cardFlipFront;
}

.back {
    -webkit-animation-name: cardFlipBack;
    -moz-animation-name: cardFlipBack;
    -ms-animation-name: cardFlipBack;
    animation-name: cardFlipBack;
}

@keyframes cardFlipFront {
    0% {
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    22.5% {
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    27.5% {
        -moz-transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
    72.5% {
        -moz-transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
    77.5% {
        -moz-transform: rotateY(0deg);
        -o-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
}

@-webkit-keyframes cardFlipFront {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    22.5% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    27.5% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
    72.5% {
        -webkit-transform: rotateY(180deg);
        transform: rotateY(180deg);
    }
    77.5% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    100% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
}

@keyframes cardFlipBack {
    0% {
        -moz-transform: rotateY(-180deg);
        -ms-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }
    22.5% {
        -moz-transform: rotateY(-180deg);
        -ms-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }
    27.5% {
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    72.5% {
        -moz-transform: rotateY(0deg);
        -ms-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    77.5% {
        -moz-transform: rotateY(-180deg);
        -ms-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }
    100% {
        -moz-transform: rotateY(-180deg);
        -ms-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }
}

@-webkit-keyframes cardFlipBack {
    0% {
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }
    22.5% {
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }
    27.5% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    72.5% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    77.5% {
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }
    100% {
        -webkit-transform: rotateY(-180deg);
        transform: rotateY(-180deg);
    }
}

.front,
.back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.front {
    z-index: 1;
}

.back {
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

.about-footprint {
    margin-top: 60px;
}

.projectdetail-head {
    margin: 60px 0px;
}

.prodetail-image {
    position: relative;
    padding-right: 40px;
    min-height: 337px;
    border-radius: 10px;
}

.prodetail-image .img-responsive {
    border-radius: 10px;
    width: 100%;
}

.prodetail-content p {
    color: rgb(0 0 0 / 60%);
    text-align: justify;
}

.prodetail-content p:last-child {
    margin-bottom: 0px;
}

.prodetail-content span {
    position: relative;
    display: block;
    z-index: 1;
}

.prodetail-content span::after {
    position: absolute;
    content: '';
    background: #B7AAA4;
    width: 92%;
    height: 1px;
    right: 0;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
}

.prodetail-content span img {
    width: 28px;
}

.prodetail-content .heading {
    margin-top: 30px;
    font-size: 56px;
    text-transform: lowercase;
}

.prodetail-content ul {
    margin: 30px 0px !important;
}

.prodetail-content ul li {
    font-size: 15px;
    position: relative;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .5px;
    color: #000;
    display: inline-block;
    margin-right: 25px;
    padding-left: 15px;
}

.prodetail-content ul li:last-child {
    margin-right: 0px;
}

.prodetail-content ul li:before {
    position: absolute;
    content: '';
    background: #B8ABA5;
    width: 6px;
    height: 6px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}

.prodetail-portfolio {
    background: #F2F0EF;
    padding: 60px 0px 40px;
}

.prodetail-other {
    margin: 60px 0px;
}

.project-otherslider .projectlist-box {
    margin-top: 0px;
}

.project-otherslider .slick-slide>div {
    padding: 0px 10px;
}

.porfolio-view {
    overflow: hidden;
    border-radius: 10px;
    display: block;
    margin-bottom: 24px;
}

.porfolio-view img {
    transition: transform 0.4s ease-in-out 0s;
    width: 100%;
    object-fit: cover;
}

.porfolio-view:hover img {
    transform: scale(1.05);
}

.half-view img {
    height: 487px;
}

.blogdetail-content {
    background: #F2F0EF;
    padding-bottom: 60px;
}

.blogdetail-box {
    position: relative;
    z-index: 1;
    margin-top: -32px;
}

.blogdetail-box::before {
    position: absolute;
    content: '';
    background: #fff;
    border-radius: 10px 35px 35px 10px;
    width: 50%;
    height: 100%;
    z-index: -1;
    left: 0;
    top: 0;
}

.blogdetail-box::after {
    position: absolute;
    content: '';
    background: #fff;
    border-radius: 35px 10px 10px 35px;
    width: 50%;
    height: 100%;
    z-index: -1;
    right: 0;
    top: 0;
}

.blogdetail-inner {
    padding: 70px 50px;
    overflow: hidden;
}

.blogdetail-inner p {
    color: rgb(0 0 0 / 60%);
    margin-bottom: 30px;
    text-align: justify;
}

.blogdetail-inner h1 {
    color: #000;
    text-transform: uppercase;
    font-size: 28px;
    margin-bottom: 20px;
    line-height: 1.3;
}

.blogdetail-inner h2,
.blogdetail-inner h3,
.blogdetail-inner h4,
.blogdetail-inner h5 {
    color: #000;
    font-size: 22px;
    margin-bottom: 13px;
}

.blogdetail-inner ul {
    margin-bottom: 1rem !important;
    padding-left: 15px !important;
    list-style: disc !important;
}

.blogdetail-inner ul li {
    font-size: 14px;
    font-weight: 400;
    color: rgb(0 0 0 / 60%);
    font-family: "Inter", sans-serif;
}

.bloginner-image {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
}

.bloginner-image img {
    border-radius: 10px;
    width: 49%;
}

.blogdivide-line {
    border-bottom: 1px solid #B7AAA4;
    margin-bottom: 30px;
}

.blogdetail-social {
    border-top: 1px solid #B7AAA4;
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blogdetail-social span {
    color: #000;
    font-weight: 500;
    font-size: 15px;
    margin-right: 8px;
}

.blogdetail-social ul li {
    display: inline-block;
    margin: 0px 4px;
}

.blogdetail-social ul li a {
    color: #B7AAA4;
    border: 1px solid #B7AAA4;
    border-radius: 5px;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    transition: all 0.2s ease-In;
}

.blogdetail-social ul li a:hover {
    color: #fff;
    border-color: #B7AAA4;
    background: #B7AAA4;
}

.blogdetail-other {
    margin: 60px 0px;
}

.similar-posts {
    position: relative;
}

.otherblog-slider .slick-slide>div {
    padding: 0px 10px;
}

.otherblog-slider .slick-slide .bloglist-card-box {
    margin-top: 0px;
}

.otherblog-arrows .default-arrowleft,
.otherblog-arrows .default-arrowright {
    margin: 0px;
    border-radius: 0;
    background: #fff;
    position: absolute;
    top: 40%;
    transform: translateY(-50%) rotate(45deg);
}

.otherblog-arrows .default-arrowleft:hover,
.otherblog-arrows .default-arrowright:hover {
    background: #B3A693;
}

.otherblog-arrows .default-arrowleft svg,
.otherblog-arrows .default-arrowright svg {
    transform: rotate(-45deg);
}

.otherblog-arrows .default-arrowleft {
    left: -12px;
}

.otherblog-arrows .default-arrowright {
    right: -12px;
}

.thankyou-page {
    background: linear-gradient(to top, #F2F0EF, rgb(255 255 255 / 0%) 70%);
}

.thankyou-text {
    text-align: center;
    height: 100vh;
    position: relative;
    padding: 100px 0px 50px;
}

.thankyou-text div {
    position: absolute;
    transform: translateY(-50%);
    top: 55%;
    right: 0;
    left: 0;
    margin: 0 auto;
}

.thankyou-text .heading {
    font-size: 90px;
    letter-spacing: 4px;
    margin-bottom: 30px;
}

.thankyou-text ul {
    margin-bottom: 1rem !important;
    list-style: disc;
    list-style-position: inside;
}

.thankyou-text ul li {
    font-size: 14px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
}

.thankyou-text p a {
    font-weight: 500;
    color: #212529;
    transition: all 0.2s ease-In;
}

.thankyou-text p a:hover {
    color: #998a86;
}

.thankyou-text p strong {
    font-weight: 500;
}

.error-page {
    background: url(../public/assets/home/error-back.webp) no-repeat;
    background-size: cover;
    background-position: center;
}

.errorpage-text {
    text-align: center;
    width: 100%;
    height: 100vh;
    position: relative;
}

.errorpage-text div {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.errorpage-text .heading {
    color: #fff;
    font-size: 150px;
    letter-spacing: 8px;
    margin-bottom: 10px;
}

.errorpage-text p {
    color: #fff;
}

.career-detail {
    padding: 60px 0px;
}

.jobhead-image {
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
    padding-right: 40px;
    margin-top: 15px;
}

.jobhead-image .img-responsive {
    border-radius: 10px;
}

.job-discrip .heading {
    text-transform: lowercase;
}

.job-discrip p {
    color: rgb(0 0 0 / 60%);
    margin-bottom: 22px;
    text-align: justify;
}

.job-discrip ul {
    list-style: disc;
    padding-left: 20px !important;
    margin-bottom: 22px !important;
}

.job-discrip ul li {
    color: rgb(0 0 0 / 60%);
    font-size: 14px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    text-align: justify;
    margin-bottom: 2px;
}

.job-discrip span {
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .5px;
    color: #B7AAA4;
    margin-bottom: 6px;
}

.job-typelocate {
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
    padding: 15px 0px;
    margin: 35px 0px 28px;
    display: flex;
}

.job-typelocate small {
    font-weight: 600;
}

.job-typelocate div {
    margin-right: 80px;
}

.job-typelocate div:last-child {
    margin-right: 0px;
}

.job-typelocate div span {
    margin-bottom: 2px;
}

.service-listpage {
    margin: 60px 0px 50px;
    min-height: 80vh;
}

.servicelist-box {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 24px;
    min-height: 330px;
}

.servicelist-box a {
    display: block;
    position: relative;
}

.servicelist-box a:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, transparent, rgb(0 0 0 / 50%) 100%);
    z-index: 1;
}

.servicelist-box div {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0px 30px 40px;
    width: 100%;
    z-index: 1;
    text-align: center;
}

.servicelist-box div h4 {
    color: #fff;
    font-size: 54px;
    margin-bottom: 0px;
    font-family: "Deluce";
    line-height: 1;
    text-transform: lowercase;
}

.servicelist-box .img-responsive {
    object-fit: cover;
    transition: transform 0.4s ease-in-out 0s;
    width: 100%;
}

.servicelist-box:hover .img-responsive {
    transform: scale(1.05);
}

.service-namefixed {
    background: #fff;
    padding: 3px 12px 5px;
    -webkit-animation: slideDownBar 0.5s ease-out;
    animation: slideDownBar 0.5s ease-out;
    position: fixed;
    left: 0;
    right: 0;
    top: 59px;
    width: 100%;
    z-index: 2;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04);
    display: none;
}

.service-namefixed .heading {
    font-size: 26px;
    text-transform: lowercase;
    text-align: center;
}

.title-fixed {
    display: block;
}

.service-detail-class {
    display: none;
}


.term-policy{
padding: 60px 0px;
}

.termpolicy-text {
position: sticky;
top: 100px;
padding-right: 40px;
}

.termpolicy-text .heading{
margin-bottom: 25px;
}

.termpolicy-detail{
background: #F5F5F5;
border-radius: 8px;
padding: 25px 25px 10px;
}

.policy-accord{
margin-bottom: 13px;
}

.policy-accord .card {
border: 0px;
border-radius: 0px;
background: none;
}

.policy-accord .card-header {
padding: 0px;
background-color: transparent;
border: 0px;
border-bottom: 1px solid #B7A9A5;
}

.policy-accord .accordion-item {
background: none;
border: 0px;
}

.policy-accord .accordion-body {
padding: 0px 0px 20px;
}

.policy-accord .accordion-body p {
margin: 0px;
color: rgb(41 41 29 / 60%);
line-height: 1.7;
text-align: justify;
}

.policy-accord .accordion-body ul {
list-style: disc;
padding-left: 15px !important;
}

.policy-accord .accordion-body ul li {
color: rgb(41 41 29 / 60%);
font-size: 14px;
font-weight: 400;
font-family: "Inter", sans-serif;
}

.policy-accord .accordion-button {
background-color: transparent !important;
box-shadow: none !important;
padding: 20px 0px;
color: #000000 !important;
font-weight: 600;
justify-content: space-between;
font-size: 13px;
text-transform: uppercase;
letter-spacing: .5px;
}

.policy-accord .accordion-button span {
font-size: 18px;
line-height: 1;
margin-left: 12px;
width: 20px;
height: 20px;
display: flex;
align-items: center;
justify-content: center;
background: #B7AAA5;
border: 1px solid #B7AAA5;
font-weight: 400;
}

.policy-accord .accordion-button label {
cursor: pointer;
width: 90%;
}

.policy-accord .active.card .accordion-button span{
background: transparent;
}

.policy-accord .accordion-button::after {
display: none;
}

.policy-accord .card:last-child .card-header {
border-bottom:0px
}

.policyaccord-head{
color: #000000;
text-transform: uppercase;
font-size: 17px;
font-weight: 600;
}

.termpolicy-detail p {
color: rgb(0 0 0 / 60%);
text-align: justify;
}

.termpolicy-detail label {
color: rgb(0 0 0 / 60%);
font-size: 14px;
font-weight: 500;
font-family: "Inter", sans-serif;
display: block;
margin-bottom: 1px;
}

.termpolicy-detail ul li {
color: rgb(0 0 0 / 60%);
font-size: 14px;
font-weight: 400;
font-family: "Inter", sans-serif;
margin-bottom: 2px;
}

.termpolicy-detail ul {
list-style: disc;
padding-left: 15px !important;
margin-bottom: 1rem !important;
}

.termpolicy-detail a {
color: rgb(0 0 0 / 60%);
}

.termpolicy-detail a:hover {
color: rgb(0 0 0 / 80%);
}


.zohoform{
width: 100%;
height: 450px;
display: block;
}